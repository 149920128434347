* {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
}

header {
  padding: 0 !important;
}

body {
  margin: 0;
  padding: 0 !important;
  overflow: auto !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  height: 100%;
  width: 100%;
  min-width: 400px;
}

#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 480px) {
  .Recording-btn span {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .xs-thumbnailColumn {
    width: 130px !important;
  }

  .top-chanel-img {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .sm-hidden {
    display: none !important;
  }

  .xs-padding {
    padding: 0 !important;
  }

  .xs-img-width {
    width: 80% !important;
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 1200px) {
}
